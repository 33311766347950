import React from "react";
import PricingModule from "../PricingModule";
import PricingHero from "../PricingHero";
import PricingModuleTwo from "../PricingModuleTwo";
import PricingHeroTwo from "../PricingHeroTwo";
import StatsPricing from "../StatsPricing";
import Testimonial from "../Testimonial";
import Faq from "../Faq"
import Newsletter from '../Newsletter'

function Packages() {
  return (
    <div>
      <PricingHero />
      <StatsPricing />
      <PricingModule />
      <PricingModuleTwo />
      <Testimonial /> <br /><br /><br /><br /><br /><br />
      <Faq />
      <PricingHeroTwo/>
      <Newsletter />
    </div>
  );
}

export default Packages;
