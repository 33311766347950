import React from "react";
import phoneIco from "./images/ico/phone-511.png";
import globe from "./images/ico/globe.png";
import fast from "./images/ico/fast.png";
import unlimited from "./images/ico/unlimited.png";
import gaming from "./images/ico/gaming.png";
import home from "./images/ico/home.png";

export default function index() {
  return (
    <div className="features-home" >
      <section className="mx-auto container py-20 ">
        <div className="flex justify-center items-center flex-col" data-aos="zoom-in-up">
          <div className="lg:text-6xl md:text-5xl text-4xl font-black leading-10 text-center text-gray-800 text-white">
            <h1>
              Excellent Reasons to Select <br /> Platinum Index
            </h1>
          </div>
          <div className="pt-24 grid lg:grid-cols-3 md:grid-cols-2 justify-center items-center xl:gap-y-16 gap-y-20 gap-x-16 lg:gap-x-20 xl:gap-x-0 lg:px-10 xl:px-0">
            <div className="cursor-pointer hover:shadow py-6 xl:px-4 rounded xl:w-96 w-60 flex justify-center items-center flex-col">
              <div className="mb-6">
                <img src={unlimited} alt="" srcset="" width={"75px"} />
              </div>
              <div className="text-gray-800 text-white text-2xl font-semibold text-center">
                <h2>Unlimited Data</h2>
              </div>
              <div className="text-gray-600 text-white mt-2 text-lg text-center ">
                <p>
                  With Platinum Index, you are guaranteed unlimted data, no data
                  cap, no limits
                </p>
              </div>
            </div>
            <div className="cursor-pointer hover:shadow py-6 xl:px-4 rounded xl:w-96 w-60 flex justify-center items-center flex-col">
              <div className="mb-6">
                <img src={fast} alt="" srcset="" width={"75px"} />
              </div>
              <div className="text-gray-800 text-white text-2xl font-semibold text-center">
                <h2>Up to 1Gbps Download Speed</h2>
              </div>
              <div className="text-white  mt-2 text-lg text-center">
                <p>
                  Platinum index provides 1Gbps per seconds for download for
                  quick downloading
                </p>
              </div>
            </div>
            <div className="cursor-pointer hover:shadow py-6 xl:px-4 rounded xl:w-96 w-60 flex justify-center items-center flex-col">
              <div className="mb-6">
                <img src={globe} alt="" srcset="" width={"55px"} />
              </div>
              <div className="text-white text-2xl font-semibold text-center">
                <h2>Access to all Websites</h2>
              </div>
              <div className="text-white mt-2 text-lg text-center">
                <p>
                  With Platinum Index, you have unrestricted access to all
                  websites
                </p>
              </div>
            </div>
            <div className="cursor-pointer hover:shadow py-6 xl:px-4 rounded xl:w-96 w-60 flex justify-center items-center flex-col">
              <div className="mb-6">
                <img src={gaming} alt="" srcset="" width={"75px"} />
              </div>
              <div className="text-white text-2xl font-semibold text-center">
                <h2>Seamless Gaming</h2>
              </div>
              <div className="text-white mt-2 text-lg text-center">
                <p>
                  You'll be capable of downloading massive games and playing
                  them at incredile speeds, with low latency.
                </p>
              </div>
            </div>
            <div className="cursor-pointer hover:shadow py-6 xl:px-4 rounded xl:w-96 w-60 flex justify-center items-center flex-col">
              <div className="mb-6">
                <img src={home} alt="" srcset="" width={"75px"} />
              </div>
              <div className="text-white text-2xl font-semibold text-center">
                <h2>Working From Home</h2>
              </div>
              <div className="text-white mt-2 text-lg text-center">
                <p>
                  You'll be able to download and upload files you need to keep
                  business running smoothly
                </p>
              </div>
            </div>
            <div className="cursor-pointer hover:shadow py-6 xl:px-4 rounded xl:w-96 w-60 flex justify-center items-center flex-col">
              <div className="mb-6">
                <img src={phoneIco} alt="" srcset="" width={"55px"} />
              </div>
              <div className="text-white text-2xl font-semibold text-center">
                <h2>24/7 Support Team</h2>
              </div>
              <div className="text-white mt-2 text-lg text-center">
                <p>We are always here to support you, from Monday to Sunday </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
