import React from "react";
import ifo from "../Components/images/ifo.jpg";

const Index = () => (
  <div className="overflow-y-hidden">
    <div className="xl:mx-auto xl:container  xl:px-20 md:px-6 px-4 py-12">
      <div className="lg:flex items-center justify-center lg:space-x-12 2xl:space-x-6">
        <div className="hidden lg:block lg:w-3/5 xl:w-3/5 w-full lg:mt-0 mt-6">
          <img
            src={ifo}
            alt="ongoing meeting"
            className="w-full obejct-fit object-center object-fill h-full"
          />
        </div>
        <div className="" data-aos="fade-left">
          <p className="lg:text-4xl text-3xl font-extrabold leading-9 text-gray-800">
            Enterprise
          </p>
          <p className="text-lg leading-7 text-gray-600 mt-4 xl:w-7/12 w-full">
            Large organizations often process large amount of informations and
            resources Platinum Index offers you packages that ease the process
            with little or no efforts.
          </p>
          <div className="lg:hidden lg:w-3/5 xl:w-3/5 w-full lg:mt-0 mt-6">
            <img
              src={ifo}
              alt="ongoing meeting"
              className="w-full obejct-fit object-center object-fill h-full"
            />
          </div>
          <div className="mt-6 md:mt-10 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 lg:mt-6 2xl:mt-12">
            <div className="flex items-center">
              <div className="w-16 h-16 relative">
                <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg1.svg"
                    alt="clock"
                  />
                </div>
              </div>
              <div className="flex items-start flex-col ml-6 pt-8">
                <h2 className="text-lg font-semibold leading-4 text-gray-800">
                  Security
                </h2>
                <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600">
                  No matter your business size, security should be a major
                  priority.
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-16 h-16 relative">
                <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg2.svg"
                    alt="Friendly"
                  />
                </div>
              </div>
              <div className="flex items-start flex-col ml-6 pt-8">
                <h2 className="text-lg font-semibold leading-4 text-gray-800">
                  Business
                </h2>
                <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600">
                  We provide connectivity for any business need, from flexible
                  broadband packages to powerful, reliable Ethernet connections.
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-16 h-16 relative">
                <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg3.svg"
                    alt="Creative"
                  />
                </div>
              </div>
              <div className="flex items-start flex-col ml-6 pt-8">
                <h2 className="text-lg font-semibold leading-4 text-gray-800">
                  Voice
                </h2>
                <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600">
                  However you communicate, we’ve got the solution for you.
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-16 h-16 relative">
                <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                  <img
                    src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg4.svg"
                    alt="Achievments"
                  />
                </div>
              </div>
              <div className="flex items-start flex-col ml-6 pt-8">
                <h2 className="text-lg font-semibold leading-4 text-gray-800">
                  Realiable
                </h2>
                <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600">
                  Connect all your devices
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default Index;
