import React from "react";

const PricingHeroTwo = () => {
  return (
    <div>
      <div className="parallax-pricing-two">
        <div
          className="PricingHero-text-Two"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {/*  <p className="promo-price">Promo</p> */}
          <h2 className="ph-text-one">Loyalty Membership Registration. </h2>
          <p>Click the button below to apply</p>
          <div className="flex flex-wrap mt-8 text-center gap-4">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeoLDhrzjB5Y7mgU0wJXL88kSDA_B37qBRtRRJx215fVK2N9A/viewform?usp=sf_link"
              target={"_blank"}
              className="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-indigo-600 sm:w-auto active:bg-rose-500 hover:bg-rose-700 focus:outline-none focus:ring"
            >
              Get Started
            </a>

            {/*               <a
                href="#"
                className="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-rose-600 sm:w-auto hover:text-rose-700 active:text-rose-500 focus:outline-none focus:ring"
              >
                Learn More
              </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingHeroTwo;
