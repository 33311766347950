import React from "react";
import CookieConsent from "react-cookie-consent";
import Features from "../Features";
import CarouselContainer from "../CarouselContainer";
import HeroTwo from "../HeroTwo";
import Hero from "../Hero";
import Step from "../Step";
import PricingHeroTwo from "../PricingHeroTwo";
import MapApi from "../Data/MapApi";
import HomeWcuTwo from "../HomeWcuTwo"
import HomeWcu from "../HomeWcu"
import Newsletter from "../Newsletter"
import Testimonial from "../Testimonial"
function Home() {
  return (
    <div className="">
      <CarouselContainer />
      <br />
      <Hero />
      <HeroTwo />
      <Features /> <br /><br /><br />
      <HomeWcu /> <br /><br /><br />
      <HomeWcuTwo /> <br /><br /><br />
      <PricingHeroTwo />
      <Step />
      <MapApi />
      <Newsletter />

      <CookieConsent
        location="bottom"
        style={{
          background: "#000",
          color: "orange",
        }}
        buttonText="Welcome"
        buttonStyle={{
          color: "#000",
          background: "#fff",
          fontSize: "14px",
        }}
        /*  expires={"1"} */
      >
        We use cookies to make HubSpot's website a better place. Cookies help to
        provide a more personalized experience and relevant advertising for you,
        and web analytics for us. To learn more about the different cookies
        we're using, check out our Cookie Policy (baked goods not included).
      </CookieConsent>
    </div>
  );
}

export default Home;
