import React from "react";
import Contact from "./Navbar/Contact";
import heroone from "./images/hero-one.jpg";

function Home() {
  return (
    <div className="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
      <div
        className="flex flex-col items-start w-full max-w-xl px-4 mx-auto lg:px-8 lg:max-w-screen-xl"
        data-aos="zoom-in"
      >
        <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
          <div className="max-w-xl mb-6">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xl font-semibold tracking-wider text-fontColor uppercase rounded-full bg-teal-accent-400">
                WHO WE ARE
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans  font-bold tracking-tight text-gray-900 hero-txt-one sm:leading-none">
              Empowering people
              <br className=" md:block" />
              through{" "}
              <span className="inline-block text-deep-purple-accent-400">
                technology
              </span>
            </h2>
            <p className="text-base text-gray-700 md:text-lg hero-txt-three">
              Our ability to identify, satisfy, and exceed today's market needs
              is a result of over a decade of experience, as well as our
              commitment, drive, and passion, which is realized through highly
              skilled and well-seasoned professionals.
            </p>
          </div>
          <div className="flex flex-col items-center md:flex-row">
            <a
              href="/contact"
              className=" bg-buttonColor inline-flex items-center justify-center w-full h-12 px-6 mb-3 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto md:mr-4 md:mb-0 bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            >
              Apply Now
            </a>
{/*             <a
              href="/"
              aria-label=""
              className="inline-flex items-center font-semibold text-gray-800 transition-colors duration-200 hover:text-deep-purple-accent-700"
            >
              Learn more
            </a> */}
          </div>
        </div>
      </div>
      <div
        className="inset-y-0 right-0 w-full max-w-xl px-4 mx-auto lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0"
        data-aos="fade-left"
      >
        <img
          className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none sm:h-96 lg:h-full"
          src={heroone}
          alt=""
        />
      </div>
    </div>
  );
}

export default Home;
