import React from "react";

const PricingModuleTwo = () => {
  return (
    <div>
      <h1 className="font-bold xl:leading-loose text-gray-800 text-center lg:text-6xl md:text-5xl text-4xl">
        SME Plans
      </h1>{" "}
      <br />
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid max-w-md gap-10 row-gap-5 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-2 xl:max-w-screen-lg sm:mx-auto">
          <div className="card-element border-1 border-pricingBorder flex flex-col justify-between p-8 transition-shadow duration-300 bg-white rounded shadow-sm sm:items-center hover:shadow">
            <div className="text-center">
              <div className="text-lg font-semibold">Platinum Compact SME</div>
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-5xl font-bold">₦30,000</div>
              </div>
              <div className="mt-2 space-y-3">
                <div className="text-gray-700 font-semibold">
                  Bandwidth Speed (8 Mbps)
                </div>
                <div className="text-gray-700">Vat 7.5% inclusive</div>
                <div className="text-gray-700">Per Month</div>
              </div>
            </div>
            <div>
              <a
                href="/"
                className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 bg-gray-800 rounded shadow-md hover:bg-buttonColor focus:shadow-outline focus:outline-none"
              >
                Buy Plan
              </a>
              <p className="max-w-xs mt-6 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                Terms & Conditions Apply
              </p>
            </div>
          </div>
          <div className="card-element border-1  border-pricingBorder relative flex flex-col justify-between p-8 transition-shadow duration-300 bg-white rounded shadow-sm sm:items-center">
            <div className="absolute inset-x-0 top-0 flex justify-center -mt-3">
              <div className=" bg-fontColor inline-block px-3 py-1 text-xs font-medium tracking-wider text-white uppercase rounded">
                Most Popular
              </div>
            </div>
            <div className="text-center">
              <div className="text-lg font-semibold">Platinum Stardard SME</div>
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-5xl font-bold">₦35,000</div>
                <div className="text-gray-700">/ mo</div>
              </div>
              <div className="mt-2 space-y-3">
                <div className="text-gray-700 font-semibold">
                  Bandwidth Speed (10 Mbps)
                </div>
                <div className="text-gray-700">Vat 7.5% inclusive</div>
                <div className="text-gray-700">Per Month</div>
              </div>
            </div>
            <div>
              <a
                href="/"
                className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-gray-800 hover:bg-buttonColor focus:shadow-outline focus:outline-none"
              >
                Buy Pro
              </a>
              <p className="max-w-xs mt-6 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                Terms & Conditions Apply
              </p>
            </div>
          </div>
          <div className="card-element border-1 border-pricingBorder  flex flex-col justify-between p-8 transition-shadow duration-300 bg-white rounded shadow-sm sm:items-center">
            <div className="text-center">
              <div className="text-lg font-semibold">Platinum Gold SME</div>
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-5xl font-bold">₦45,000</div>
                <div className="text-gray-700">/ mo</div>
              </div>
              <div className="mt-2 space-y-3">
                <div className="text-gray-700 font-semibold">
                  Bandwidth Speed (12 Mbps)
                </div>
                <div className="text-gray-700">Vat 7.5% inclusive</div>
                <div className="text-gray-700">Per Month</div>
              </div>
            </div>
            <div>
              <a
                href="/"
                className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 bg-gray-800 rounded shadow-md hover:bg-buttonColor focus:shadow-outline focus:outline-none"
              >
                Buy Plan
              </a>
              <p className="max-w-xs mt-6 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                Terms & Conditions Apply
              </p>
            </div>
          </div>
          <div className="card-element border-1 border-pricingBorder  flex flex-col justify-between p-8 transition-shadow duration-300 bg-white rounded shadow-sm sm:items-center">
            <div className="text-center">
              <div className="text-lg font-semibold">Platinum Premium SME</div>
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-5xl font-bold">₦50,000</div>
                <div className="text-gray-700">/ mo</div>
              </div>
              <div className="mt-2 space-y-3">
                <div className="text-gray-700 font-semibold">
                  Bandwidth Speed (15 Mbps)
                </div>
                <div className="text-gray-700">Vat 7.5% inclusive</div>
                <div className="text-gray-700">Per Month</div>
              </div>
            </div>
            <div>
              <a
                href="/"
                className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 bg-gray-800 rounded shadow-md hover:bg-buttonColor focus:shadow-outline focus:outline-none"
              >
                Buy Plan
              </a>
              <p className="max-w-xs mt-6 text-xs text-gray-600 sm:text-sm sm:text-center sm:max-w-sm sm:mx-auto">
                Terms & Conditions Apply
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingModuleTwo;
