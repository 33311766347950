import React from "react";
import ServicesFeatures from "./ServicesFeatures";

const Servicesbanner = () => {
  return (
    <div>
      <div className="relative bg-center bg-no-repeat bg-cover services-banner-bg">
        <div className="absolute inset-0 bg-white/25 sm:bg-transparent sm:bg-gradient-to-r sm:from-white/25 sm:to-white/25"></div>

        <div className="relative px-4 py-32 mx-auto max-w-screen-xl sm:px-6 lg:px-8 lg:h-screen lg:items-center lg:flex">
          <div className="max-w-xl text-center sm:text-left" data-aos="zoom-in">
            <h1 className="text-3xl sm:text-left font-extrabold sm:text-5xl" >
              We are Professional
              <strong className="block font-extrabold text-rose-700">
                Internet Service <div className="provider-text">Provider</div>
              </strong>
            </h1>
{/* 
            <p className="max-w-lg mt-4 sm:leading-relaxed sm:text-xl sm:text-left">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt
              illo tenetur fuga ducimus numquam ea!
            </p> */}

            <div className="flex flex-wrap mt-8 text-center gap-4">
              <a
                href="/contact"
                className="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-rose-600 sm:w-auto active:bg-rose-500 hover:bg-rose-700 focus:outline-none focus:ring"
              >
                Get Started
              </a>

{/*               <a
                href="#"
                className="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-rose-600 sm:w-auto hover:text-rose-700 active:text-rose-500 focus:outline-none focus:ring"
              >
                Learn More
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <ServicesFeatures />
    </div>
  );
};

export default Servicesbanner;
