import React from "react";
import ReactPlayer from "react-player";
import ispVid from "./images/isp.mp4";

const AboutUsInfoTwo = () => {
  return (
    <div>
      <div className="lg:mt-14 sm:mt-10 mt-12">
        <div className="lg:block hidden w-full">
          <video src={ispVid} autoPlay loop muted width={"100%"} />
        </div>
        <div className="lg:hidden sm:block hidden w-full">
          <video src={ispVid} autoPlay loop muted width={"100%"} />
        </div>
        <div className="sm:hidden block w-full">
          <video src={ispVid} autoPlay loop muted width={"100%"} />
        </div>
      </div>
      <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
        <div className="lg:mt-16 sm:mt-12 mt-16 flex lg:flex-row justify-between flex-col lg:gap-8 gap-12">
          <div className="w-full xl:w-5/12 lg:w-6/12" data-aos="fade-right">
            <h2 className="font-bold lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800">
              Who we are
            </h2>
            <p className="font-normal text-base leading-6 text-gray-600 mt-4">
              Platinum Index is an innovative broadband internet service Company
              providing the most reliable internet solutions , data management
              and extended professional services , our reputation for integrity,
              quality service, delivery, and excellence in management has
              endeared much business, government and international agencies to
              become our loyal partners round Nigeria.
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              PLATINUM INDEX operates virtually in all the state shaping its
              network future of business and society by creating value for
              customer, employees and investors. Incorporated in July 2018,
              Platinum Index is also licensed by the Nigeria Communications
              Commission (NCC) as Internet service provider in Nigeria.
            </p>
            <p className="font-normal text-base leading-6 text-gray-600 mt-6">
              Our ability to identify, satisfy, and exceed today's market needs
              is a result of over a decade of experience, as well as our
              commitment, drive, and passion, which is realized through highly
              skilled and well-seasoned professionals.
            </p>
          </div>
          <div className="lg:flex items-center w-full lg:w-1/2 " data-aos="fade-left">
            <div className="lg:block hidden w-full">
              <ReactPlayer url="https://youtu.be/muP5m7ZVg1c" controls={true} />
            </div>
            <div className="lg:hidden sm:block hidden w-full h-3/4">
              <ReactPlayer
                url="https://youtu.be/muP5m7ZVg1c"
                controls={true}
                width={"100%"}
              />
            </div>
            <div className="sm:hidden block w-full">
              <ReactPlayer
                url="https://youtu.be/muP5m7ZVg1c"
                controls={true}
                width={"100%"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsInfoTwo;
