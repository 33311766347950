import React, { useState } from "react";
import Pl from '../images/pilLogo.png'
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";


function Navbar() {
  const [Mobile, setMobile] = useState(false);
  return (
    <nav className="navbar font-sans font-medium">
      <Link to="./">
        {/* <h3 className="logo">Logo</h3> */} <img src={Pl} alt="" srcSet="" className="logoimg"/>
      </Link>
      <ul
        className={Mobile ? "nav-links-mobile" : "nav-links"}
        onClick={() => setMobile(false)}
      >
        <Link to="/">
          <li>Home</li>
        </Link>
        <Link to="/about">
          <li>About Us</li> 
        </Link>
        <Link to="/services">
          <li>Services</li>
        </Link>
        <Link to="/packages">
          <li>Packages</li>
        </Link>
        <Link to="/contact">
          <li>Contact</li>
        </Link>
        <Link to="/blog">
          <li>Blog</li>
        </Link>
      </ul>
      <button className="mobile-menu-icon" onClick={() => setMobile(!Mobile)}>
        {Mobile ? <ImCross /> : <FaBars />}
      </button>
    </nav>
  );
}

export default Navbar;
