import React from "react";
import AboutUsInfo from "../AboutUsInfo";
import AboutUsInfoTwo from "../AboutUsInfoTwo";
import Partners from "../Partners";

function AboutUs(props) {
  return (
    <div>
      <div className="aboutus-container">
        {/* ====== */}
        <div className="parallax">
          <div className="aboutus-hero">
            <h1
              className=".aboutus-title"
              id="abs-title"
              data-aos="fade-up"
              data-aos-duration="9000"
            >
              About Us | Platinum
            </h1>
          </div>
          {/* ============= */}
        </div>
        <AboutUsInfo />
        <AboutUsInfoTwo />
        {/* <section className="abs-container">
          <div className="abs-item"></div>
          <div className="abs-item">
            <div className="abs-layout-one">{props.outone}</div> <br />
            <div className="abs-layout-two">{props.outtwo}</div> <br />
            <div className="abs-layout-three">{props.outthree}</div> <br />
            <div className="abs-layout-four">{props.outfour}</div>
          </div>
        </section>


        <div className="abs-overall">
          <div className="abs-container--section">
            <div className="abs-item--section">
              <div className="abs-layout-two">
                <h4>Mission</h4>
                <p>{props.mission}</p>
              </div>
            </div>
            <div className="abs-item--section">
              <h4>Vision</h4>
              <p>
                {props.vision} <br /> <b>“TOMORROW TECHNOLOGY TODAY”.</b>
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <Partners />
    </div>
  );
}

export default AboutUs;
