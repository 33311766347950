export default [
  {
    id: 1,
    mission:
      " We strive to exceed our customer's expectation with our superior customer and technical support, while exceeding our  staff expectation to provide quality, affordable and dependable network solution to the average Nigerian and West African to provide customers with a complete solution to all their current and future Internet and private network needs.",
    vision:
      "To be the most innovative technology service firm in our chosen market, offering delightful IT solutions. Every member of our staff is committed to delivering excellence through our quality motto",
    layoutone:
      "Ngcom is a network company authorized by Nigerian Communications Company (NCC) to provide internet services across Nigeria. Ngcom Network Solutions is one of Nigeria's leading Internet service providers offering UNLIMITED INTERNET service to residential and business users.",
    layouttwo:
      "Our core business is to link you to what matters most to you, by offering limitless Internet access with absolutely no data or time caps through fiber optic and fixed wireless.",
    layoutthree:
      "We are also committed to continuing to deliver on our commitments to you by delivering a better, cheaper, more secure and affordable broadband Internet.",
    layoutfour:
      "We continue to empower Nigerian businesses and families to interact with their environment as we deliver our unique services across the region. Our esteemed customers will continue to enjoy smart networking, cost-effective high-speed Internet service, 24/7 accesses / support and pay “less for more",
  },
];
