import React from "react";
import { Carousel } from "react-bootstrap";

import image1 from "./images/slide-01.jpg";
import image2 from "./images/slide-02.jpg";
import image3 from "./images/bimg.jpg";
import image4 from "./images/image4.jpg";

const CarouselContainer = () => {
  return (
    <div className="home-carousel-container">
      <Carousel fade={true} pause={false}>
        <Carousel.Item interval={7000}>
          <img className="d-block w-100" src={image1} alt="First slide" />
          <Carousel.Caption>
            <div
              className="carouselcap"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <h3>Platinum Index:</h3>
              <p>For all your internet needs at lighting speed 🚀</p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img className="d-block w-100" src={image2} alt="Third slide" />
          <Carousel.Caption>
            <div
              className="carouselcap"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
            >
              <h3>Platinum Index:</h3>
              <p>
                Take control of your business with <br /> Platinum technology
                solutions
              </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            className="d-block w-100"
            id="kenh"
            src={image3}
            alt="Third slide"
          />
          <Carousel.Caption>
            <div className="carouselcap">
              <p className="banner-three">
                No Limit with Platinum Index, <br /> <i> That's why we say </i>{" "}
                <br /> ...Get Connected, <br /> Stay Connected...
              </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={2000}>
          <img
            className="d-block w-100"
            id="kenh"
            src={image4}
            alt="Third slide"
          />
          <Carousel.Caption>
            <div className="carouselcap">
              <p className="banner-three"></p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselContainer;
