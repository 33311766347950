import React from "react";
import tbwa from "./images/tbway.png";
import beniGold from "./images/benigold.png";
import ccc from "./images/ccc.png";
import mfm from "./images/mfm.png";
import ccecc from "./images/ccecc.png";
import daystar from "./images/daystar.png";
import citiserve from "./images/citiserve.png"


function index() {
  return (
    <>
      <div className="px-4 py-12">
        <div className="lg:max-w-[1440px] md:max-w-[744px] max-w-[375px] w-full  py-12 lg:px-10  md:px-6 px-4 mx-auto">
          <div className="flex flex-col lg:justify-between lg:flex-row gap-x-8 gap-y-8">
            <div aria-label="text-area" className="mb-7" data-aos="flip-left">
              <p className="lg:text-4xl text-3xl font-semibold leading-10 text-gray-800 mb-4 lg:max-w-[405px] w-full">
                We Work with the Best Partners
              </p>
              <p className="text-base leading-normal text-gray-800 mb-[64px] lg:max-w-[513px] w-full">
                We are confident that we can find the process that will help you
                meet your goals.
              </p>
              <button className=" w-full px-4 py-4 text-base font-medium leading-none text-white duration-300 ease-in-out transform bg-gray-800 hover:bg-gray-700 lg:max-w-[187px] ">
                <a href="/about" className="text-white">
                  Read More{" "}
                </a>
              </button>
            </div>
            <div className="hidden lg:block md:block" data-aos="flip-right" data-aos-duration="7000">
              <div className="flex flex-col xl:min-w-[624px] w-full ">
                <div className="flex justify-between gap-x-12 ">
                  <img src={tbwa} alt="" srcset="" width={120} height={26} />
                  <p className="border-gray-400 border-r h-[70px]" />
                  <img
                    src={beniGold}
                    alt=""
                    srcset=""
                    width={120}
                    height={26}
                  />
                  <p className="border-gray-400 border-r h-[70px]" />
                  <img src={ccc} alt="" srcset="" width={120} height={26} />
                </div>
                <hr className="border-gray-400" />
                <div className="flex items-center justify-between gap-x-8">
                  <img src={mfm} alt="" srcset="" width={120} height={26} />
                  <p className="border-gray-400 border-r h-[90px]" />
                  <img
                    src={citiserve}
                    alt=""
                    srcset=""
                    width={190}
                    height={26}
                  />
                  <p className="border-gray-400 border-r h-[90px]" />
                  <img src={ccecc} alt="" srcset="" width={120} height={26} />
                </div>
                <hr className="border-gray-400" />
                <div className="flex items-center justify-between gap-x-8">
                  <img src={daystar} alt="" srcset="" width={120} height={26} />
                  <p className="border-gray-400 border-r h-[90px]" />
                  <img src={"https://vavafurniture.com/wp-content/uploads/2019/12/logo-1.png"} alt="" srcset="" width={190} height={26} />
                  <p className="border-gray-400 border-r h-[90px]" />
                  <img src={"https://www.one-line.com/themes/custom/one/logo.svg"} alt="" srcset="" width={120} height={26} />
                </div>
              </div>
            </div>
            <div className="block lg:hidden md:hidden">
              <div className="flex flex-col w-full ">
                <div className="flex items-center justify-center gap-x-8">
                  <img src={tbwa} alt="" srcset="" width={120} height={26} />
                  <p className="border-gray-400 border-r h-[70px]" />
                  <img
                    src={beniGold}
                    alt=""
                    srcset=""
                    width={120}
                    height={26}
                  />
                </div>
                <hr className="border-gray-400" />
                <div className="flex items-center justify-center gap-x-8">
                  <img src={ccc} alt="" srcset="" width={120} height={26} />
                  <p className="border-gray-400 border-r h-[70px]" />
                  <img src={mfm} alt="" srcset="" width={120} height={26} />
                </div>
                <hr className="border-gray-400" />
                <div className="flex items-center justify-center gap-x-8">
                  <img
                    src={citiserve}
                    alt=""
                    srcset=""
                    width={120}
                    height={26}
                  />
                  <p className="border-gray-400 border-r h-[90px]" />
                  <img src={ccecc} alt="" srcset="" width={120} height={26} />
                </div>
                <hr className="border-gray-400" />
                <div className="flex items-center justify-center gap-x-8">
                <img src={daystar} alt="" srcset="" width={120} height={26} />
                  <p className="border-gray-400 border-r h-[90px]" />
                  <img src={"https://vavafurniture.com/wp-content/uploads/2019/12/logo-1.png"} alt="" srcset="" width={120} height={26} />
                </div>
                <hr className="border-gray-400" />
                <div className="flex items-center justify-center gap-x-8 ">
                <img src={"https://www.one-line.com/themes/custom/one/logo.svg"} alt="" srcset="" width={120} height={26} />
                  <p className="border-gray-400 border-r h-[90px]" />
                  <p className="w-[101px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default index;
