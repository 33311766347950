import "./main.css";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AboutUsData from "./Components/Data/AboutUsData";
import Home from "./Components/Navbar/Home";
import AboutUs from "./Components/Navbar/AboutUs";
import Services from "./Components/Navbar/Services";
import Packages from "./Components/Navbar/Packages";
import Contact from "./Components/Navbar/Contact";
import Blog from "./Components/Navbar/Blog";
/* import Footer from "./Components/Footer"; */
/* import FooterTw from "./Components/FooterTw"; */
import Tailfooter from "./Tailfooter";

function App() {
  const aboutus = AboutUsData.map((item) => {
    return (
      <AboutUs
        key={item.id}
        mission={item.mission}
        vision={item.vision}
        outone={item.layoutone}
        outtwo={item.layouttwo}
        outthree={item.layoutthree}
        outfour={item.layoutfour}
      />
    );
  });
  return (
    <>
      <Router>
        <a
          href="https://wa.link/25xggs"
          className="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa fa-whatsapp whatsapp-icon"></i>
        </a>
        <Navbar />
        <Switch>
          <Route path="/" component={Home} exact>
            <Home />
          </Route>
          <Route path="/about" component={AboutUs} exact>
            {aboutus}
          </Route>
          <Route path="/services" component={Services} exact>
            <Services />
          </Route>
          <Route path="/packages" component={Packages} exact>
            <Packages />
          </Route>
          <Route path="/contact" component={Contact} exact>
            <Contact />
          </Route>
          <Route path="/blog" component={Blog} exact>
            <Blog />
          </Route>
        </Switch>{" "}
      </Router>
      {/*       <FooterTw /> */}
      <Tailfooter />
      {/* <Footer /> */}
    </>
  );
}

export default App;
