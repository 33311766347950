import React from "react";

const MapApi = () => {
  return (
    <div>
      <div className="map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126861.07259968805!2d3.5098620630933497!3d6.469823995893531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf7fcc7e2a88f%3A0xdd14aa53b8acd61a!2sPlatinum%20Index%20Limited!5e0!3m2!1sen!2sng!4v1659086959008!5m2!1sen!2sng"
          style={{ width: "100%", height: "400px" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default MapApi;
