import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import MapApi from "../Data/MapApi";
import Faq from "../Faq";
import { FaPhoneAlt } from 'react-icons/fa';

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4j8bydm",
        "template_vue3iej",
        form.current,
        "vJ0JIc0O6GKYAt3oH"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message Sent Successfully")
        },
        (error) => {
          console.log(error.text);
          alert("Message Not Sent")
        }
      );

      e.target.reset()
  };

  return (
    <div>
      <div className="contact-hero">
        <div className="contact-hero-typography">
          <h1>Get in touch</h1>
          <p>
            Want to get in touch? We'd love to hear from you. <br /> Here's how
            you can reach us...
          </p>
        </div>  
      </div>{" "}
      <br />
      <br />
      <center>
        <div className="contact-container">
          <div className="content">
            <div className="left-side">
              <div className="address details">
                <i className="fas fa-map-marker-alt"></i>
                <div className="topic">Address</div>
                <div className="text-one">7, Osa Paul Street</div>
                <div className="text-two">Thomas Estate, Ajah Lagos</div>
              </div>

              <div className="phone details">
                <i className="fas fa-phone-alt"></i>
                <div className="topic">Phone</div>
                <div className="text-one">+234-18888003</div>
                <div className="text-two">+234-12952961</div>
              </div>

              <div className="email details">
                <i className="fas fa-envelope"></i>
                <div className="topic">Email</div>
                <div className="text-one email-link">
                  <a href="mailto:info@platinumindex.net">
                    info@platinumindex.net
                  </a>
                </div>
                <div className="text-two email-link">
                  <a href="mailto:support@platinumindex.net">
                    support@platinumindex.net
                  </a>
                </div>
              </div>
            </div>

            <div className="right-side">
              <div className="topic-text">Send us a Message</div> <br />
              <p className="t-text">
                Feel free to talk to our online representative at any time you
                please using our Live Chat system on our website. <br /> Please
                be patient while waiting for a response. (24/7 Support!)
              </p>{" "}
              <br />
              <form action="" ref={form} onSubmit={sendEmail}>
                <div className="input-box">
                  <input
                    type="text"
                    placeholder="Enter Your Name"
                    name="user_name"
                  />
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    placeholder="Enter your email"
                    name="user_email"
                  /> 
                </div>{" "}
                <br />
                <div className="input-box message-box">
                  <textarea
                    name="message"
                    id="e-msg"
                    cols="30"
                    rows="10"
                    placeholder="Send a message"
                  ></textarea>
                </div> <br />
                <div className="button-mail">
                  <input type="submit" value="Send" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </center>
      <br />
      <br />
      <Faq />
      <MapApi />
    </div>
  );
}

export default Contact;
