import React from "react";

const PricingHero = () => {
  return (
    <div>
      <div className="parallax-pricing" >
        <div className="PricingHero-text">
          <p className="" data-aos="zoom-in-up">Radio Home Plans</p>
        </div>
      </div>
    </div>
  );
};

export default PricingHero;
