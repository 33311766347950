import React from "react";
import Servicesbanner from "../Servicesbanner";
import PricingHeroTwo from '../PricingHeroTwo'
import ServicesWcu from "../ServicesWcu";
import Testimonial from "../Testimonial";
import Newsletter from "../Newsletter"

function Services() {
  return (
    <div>
      <Servicesbanner />
      <PricingHeroTwo />
      <ServicesWcu />
      <Testimonial /> <br /><br /><br /><br /><br />
      <Newsletter />
    </div>
  );
}

export default Services;
